<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">视频观看设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getuserCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  style="width:100%"
              >
                <el-option
                    v-for="item in userCompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                  :options="areatreeList"
                  clearable
                  filterable
                  :props="propsarea"
                  size="small"
                  v-model="areaId"
              ></el-cascader>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="doAdd()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  fixed
                  :index="indexMethod"
                  width="100px"
              />
              <el-table-column
                  label="行政区划"
                  align="center"
                  show-overflow-tooltip
                  prop="areaNamePath"
              />
              <el-table-column
                  label="机构名称"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
              />
              <el-table-column
                  label="设置日期"
                  align="center"
                  prop="createTime"
              />
              <el-table-column
                  label="限制观看时长(分钟)"
                  align="center"
                  prop="duration"
              />
              <el-table-column label="操作" align="center" show-overflow-tooltip>
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleDel(scope.row.limitId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="title"
        :visible.sync="DialogVisible"
        width="45%"
        center
        top="10%"
        @close="doClose"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
      >
        <el-form-item label="机构名称：" prop="compId">
          <el-select
              size="small"
              v-model="ruleForm.compId"
              remote
              :remote-method="getuserCompanyList"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
              style="width:100%"
          >
            <el-option
                v-for="item in userCompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频观看时长：" prop="duration">
          <el-input-number
              v-model="ruleForm.duration"
              :step="1"
              :min="1"
              size="small"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "limitCompConfig",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], // 行政区划
      userCompanyList: [], //机构列表
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      areaId: "",
      compId:'',
      DialogVisible: false,
      ruleForm: {
        compId: "",
        duration: "",
      },
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getareatree();
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    /* 机构名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("sys/company/queryCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.userCompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.userCompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/biz/limit/comp/pageList",
        params,
        pageNum
      });
    },
    handleDel(limitId) {
      this.doDel({
        url: "/biz/limit/comp/delete",
        msg: "你确定要删除该机构的限制吗？",
        ps: {
          type: "post",
          data: { limitId: limitId }
        }
      });
    },
    doAdd() {
      this.ruleForm.duration = 10;
      this.DialogVisible = true;
    },
    doClose() {
      this.DialogVisible = false;
      this.ruleForm = {
        compId: "",
        duration: "",
      };
    },
    submitForm() {
      const parmars = {
        compId: this.ruleForm.compId,
        duration: this.ruleForm.duration,
      }
      this.$post("/biz/limit/comp/insert", parmars)
          .then(() => {
            setTimeout(() => {
              this.$message({
                type: "success",
                message: "保存成功"
              });
              this.getData();
              this.doClose();
            }, 300);
          })
          .catch(() => {
            return;
          });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>